// If you want to override variables do it here
//@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

.search {
  margin-top: -28px;
}

.dashboard__cardRow {
  align-items: center;
  margin-left: 20px;
}

.btn-primary {
  color: white;
  background-color: #01A768;
  border-color: #01A768;

  &:hover {
    background-color: #9DA5B1;
    color: black;
    border-color: #9DA5B1;
  }
}

//.btn-secondary {
//  color: white;
//  background-color: #01A768;
//  border-color: #01A768;
//  border-radius: 1rem;
//
//  &:hover {
//    background-color: #9DA5B1;
//    color: black;
//    border-color: #9DA5B1;
//    border-radius: 1rem;
//  }
//}

.table {
  td {
    cursor: pointer;
  }
}

.react-time-picker {
  //box-sizing: border-box;

  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
  }

  .react-time-picker__inputGroup__input {
    min-width: 2em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
}


.rmdp-input {
  border: 1px solid #c0c4d6;
  border-radius: 3px;
  height: 35px;
  margin: 1px 0;
  padding: 2px 5px;
  width: 368px;
  color: rgb(128, 136, 145)
}

.circular_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover
}


.c-sidebar-brand {
  text-decoration: none;

  .c-sidebar-brand-full {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    //border-radius: 100%;
    object-fit: fill;
    background-color: #027348;
    color: #fff;
    text-decoration: none;

    .side-img {
      width: auto;
      height: 40px;
      border-radius: 8px;
      margin-left: 8px;
    }

    .side-text {
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      text-decoration: none;
      margin-left: 8px;
    }
  }

  .c-sidebar-brand-minimized {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px !important;
    background-color: #027348;
    color: #fff;

    .side-img {
      width: 100%;
      height: auto;
      //margin-top: 3px;
      //margin-left: 2px;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  min-height: 280px;
}
textarea.form-control {
  height: auto;
  min-height: 105px;
}
.timeline-container {
  width: 100%;
}
.rct-scroll {
  width: 100%;
}
.rct-header-root {
  width: 100%;
}
.css-pdct74-MuiTablePagination-selectLabel {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  -webkit-flex-shrink: 0;
  margin: 10px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.css-levciy-MuiTablePagination-displayedRows {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 15px;
}





